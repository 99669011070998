<template>
  <div class="grid gap-4 gap-x-6 gap-y-2 p-4 text-sm grid-cols-1 lg:grid-cols-12 bg-white" v-if="!previewMode">
    <div class="p-field md:col-span-2">
      <label for="tipo_trans">Tipo transferencia</label>
      <Dropdown
        optionLabel="name"
        optionValue="id"
        v-model="filters.typeDoc"
        :options="tipoDocumento"
        class="rounded-md border-gray-300 w-full text-xs p-inputtext-xs"
        panelClass="text-xxs"
        inputClass="p-inputtext-xxs text-xxs"
        placeholder="Seleccione un documento..."
      />
    </div>
<!--    <div class="p-field md:col-span-2" v-if="filters.typeDoc === 2">-->
<!--      <label for="WhsCode">Bodega Origen</label>-->
<!--      <Dropdown-->
<!--        optionLabel="bodega"-->
<!--        optionValue="WhsCode"-->
<!--        :showClear="true"-->
<!--        :filter="true"-->
<!--        panelClass="text-xxs"-->
<!--        @click="$h.xxsInput"-->
<!--        :options="bodegas"-->
<!--        v-model="filters.codBodOrigen"-->
<!--        class="rounded-md border-gray-300 w-full text-xs p-inputtext-xs"-->
<!--        inputClass="p-inputtext-xxs text-xxs"-->
<!--        placeholder="Seleccione una bodega..."-->
<!--      />-->
<!--    </div>-->
    <div class="p-field md:col-span-2">
      <label>Numero documento</label>
      <InputText
        id="DocNum"
        v-model="filters.DocNum"
        @blur="getLoad"
        class="rounded-md border-gray-300 w-full text-xs p-inputtext-xs"
      />
    </div>
    <div class="p-field md:col-span-2">
      <label for="ToWareHouseCode">Bodega Destino</label>
      <Dropdown
        optionLabel="nombreBodega"
        optionValue="WhsCode"
        :options="uniqueWhs"
        v-model="filters.WhsCode"
        @change="onChangeBod"
        :showClear="true"
        class="rounded-md border-gray-300 w-full text-xs p-inputtext-xs"
        panelClass="text-xxs"
        inputClass="p-inputtext-xxs text-xxs"
        placeholder="Seleccione una bodega..."
        :disabled="filters.typeDoc === 1 && envioTemp.datosEnvio.some((x) => x.asignBoxes.length)"
      />
    </div>
    <div class="p-field">
      <label for="Escala">Escala</label><br>
      <div class="flex items-center">
        <Checkbox id="Escala" v-model="envioTemp.escala" :binary="true" />
      </div>
    </div>
    <div class="p-field col-span-2 flex items-end">
      <Button label="Eliminar Selección" icon="pi pi-trash" @click="deletedSelection"
              class="p-button-raised p-button-danger floating-button p-button-rounded"/>
    </div>
    <div class="p-field flex items-end justify-end col-span-3">
<!--    <div :class="`p-field ${!filters.typeDoc || filters.typeDoc === 1 || filters.typeDoc === 3 ? 'col-span-5' : 'col-span-3'} flex items-end justify-end`">-->
      <Button label="Administrar Cajas" @click="openModalBox()" icon="pi pi-table"
              class="p-button-rounded p-button-plain p-button-outlined">
      </Button>
    </div>
  </div>
  <div class="p-4 bg-white">
    <DataTable
      :value="!previewMode ? envioTemp.datosEnvio : envioStorage"
      class="p-datatable-sm text-xxs"
      showGridlines
      dataKey="Key"
      editMode="row"
      responsiveLayout="scroll"
      v-model:expandedRows="expandedRows"
      v-model:selection="selectedCustomers"
      @row-select="onRowSelect"
      @row-select-all="onRowSelectAll"
    >
      <template #empty>
        Agregue un tipo y número de documento para agregar información...
      </template>
      <Column
        selectionMode="multiple"
        bodyStyle="text-align: center;justify-content: center;"
        headerStyle="text-align: center;justify-content: center;"
        style="width: 4rem"
      />
      <Column :expander="true" headerStyle="width: 3rem"/>
      <template #expansion="slotprops1">
        <DataTable
          :value="slotprops1.data.distribucion"
          class="p-datatable-sm text-xs"
          showGridlines
          dataKey="WhsCode"
          responsiveLayout="scroll"
        >
          <Column field="" header="Almacén Destino" headerStyle="justify-content: center;" style="min-width:15rem"
                  bodyStyle="justify-content: center;text-align: center">
            <template #body="{data}">
              <div>
                {{ data.WhsCode }} - {{ data.WhsName }}
              </div>
            </template>
          </Column>
          <Column
            field="Ubicacion"
            header="Ubicación"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          />
          <Column
            field="Quantity"
            header="Cantidad"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          />
          <Column
            field="totalDistribucion"
            header="Precio por bodega"
            headerStyle="text-align: center;justify-content: center;"
            bodyStyle="text-align: center"
          >
            <template #body="{data}">
              <div>
                {{ $h.formatCurrency(data.totalDistribucion) }}
              </div>
            </template>
          </Column>
        </DataTable>
      </template>
      <Column field="" header="Artículo" headerStyle="justify-content: center;" style="min-width:30rem"
              bodyStyle="justify-content: center;text-align: center">
        <template #body="slotProps">
          <div class="grid grid-cols-1 w-full">
            <div class="flex justify-between items-center my-1">
              <div>
                <strong class="mr-1">Código Mx:</strong>{{ slotProps.data.ItemCode }}
              </div>
              <div class="flex gap-2">
                <Badge v-if="slotProps.data.ReqMarca" :value="`Req. marca`"
                       class=" bg-red-300 text-red-700 text-xxs h-4 capitalize"/>
                <Badge v-if="slotProps.data.Controlado" value="Controlado"
                       class=" bg-green-300 text-green-700 text-xxs h-4 capitalize"></Badge>
                <Badge v-if="slotProps.data.Regulado" value="Regulado"
                       class=" bg-blue-300 text-red-blue text-xxs h-4 capitalize"></Badge>
                <Badge v-if="slotProps.data.cadenaFrio" value="Cadena Frio"
                       class="bg-blue-800 text-blue text-xxs h-4"></Badge>
                <Badge v-if="slotProps.data.onHandQuantity === 0" value="Sin Inventario"
                       class="bg-red-700 text-white text-xxs h-4"></Badge>
              </div>
            </div>
            <div class="flex">
              <strong class="mr-1">Nombre:</strong> {{ slotProps.data.ItemName }}
            </div>
          </div>
        </template>
      </Column>
      <Column field="" header="Cantidad" headerStyle="justify-content: center;" style="min-width:5rem"
              bodyStyle="justify-content: center;text-align: center">
        <template #body="{data}">
          <div>
            {{ $h.formatNumber(data.Quantity) }}
          </div>
        </template>
      </Column>
      <Column field="lotes" header="Lotes" headerStyle="justify-content: center;"
              style="min-width:5rem" bodyStyle="text-align: center">
        <template #body="{data}">
          <div>
            <div v-if="data.lotes.length === 0">
              <Button type="button" class="p-button-xs" icon="pi pi-eye" label="Asignar" @click="asignarLotes(data)"/>
            </div>
          </div>
          <div class="flex gap-2 justify-center" v-if="data.lotes.length > 0">
            <div>
              <Button type="button" class="p-button-xs" icon="pi pi-eye" label="Ver"
                      @click="toggle($event,data)" aria-haspopup="true" aria-controls="overlay_panel"/>
              <OverlayPanel ref="op" appendTo="body" :showCloseIcon="true" id="overlay_panel"
                            style="width: 500px" :breakpoints="{'960px': '75vw'}">
                <DataTable
                  :value="info"
                  class="p-datatable-sm text-xs"
                  showGridlines
                  dataKey="id"
                  responsiveLayout="scroll"
                >
                  <Column field="DistNumber" header="Lote" headerStyle="justify-content: center;"
                          style="min-width:5rem" bodyStyle="text-align: center"/>
                  <Column field="Quantity" header="Cantidad" headerStyle="justify-content: center;"
                          style="min-width:2rem" bodyStyle="text-align: center">
                    <template #body="{data}">
                      <span>{{ $h.formatNumber(data.Quantity) }}</span>
                    </template>
                  </Column>
                  <Column field="ExpDate" header="Fecha Vencimiento" headerStyle="justify-content: center;"
                          style="min-width:5rem" bodyStyle="text-align: center"/>
                  <Column field="Ubicacion" header="Ubicacion" headerStyle="justify-content: center;"
                          style="min-width:5rem" bodyStyle="text-align: center"/>
                </DataTable>
              </OverlayPanel>
            </div>
            <div v-if="data.lotes.length > 0 && data.typeDoc === 'ST' && !previewMode">
              <Button type="button" class="p-button-xs p-button-warning" v-tooltip.top="'Editar asignación'" icon="pi pi-pencil" @click="asignarLotes(data)"/>
            </div>
          </div>
        </template>
      </Column>
      <Column field="" header="Precio Unitario" headerStyle="justify-content: center;" style="min-width:5rem"
              bodyStyle="justify-content: center;text-align: center">
        <template #body="{data}">
          <div>
            {{ $h.formatCurrency(data.UnitPrice) }}
          </div>
        </template>
      </Column>
      <Column field="" header="Precio Total" headerStyle="justify-content: center;" style="max-width:10rem"
              bodyStyle="justify-content: center;text-align: center">
        <template #body="{data}">
          <div>
            {{ $h.formatCurrency(data.LineTotal) }}
          </div>
        </template>
      </Column>
      <Column field="" header="Información" headerStyle="justify-content: center;" style="min-width:10rem"
              bodyStyle="justify-content: center;text-align: center">
        <template #body="{data}">
          <div>
            <strong>N° Documento: </strong><span>{{ data.DocNum }}</span>
          </div>
          <div>
            <strong>Tipo Documento: </strong><span>{{ data.typeDoc }}</span>
          </div>
          <div>
            <strong>Linea: </strong><span>{{ data.LineNum }}</span>
          </div>
        </template>
      </Column>
      <Column field="" header="Asignación" headerStyle="justify-content: center;" style="min-width:15rem"
              bodyStyle="justify-content: center;text-align: center"
              v-if="envioStorage.some((x) => x.asignBoxes.length > 0) ||  envioTemp.datosEnvio.some((x) => x.asignBoxes.length > 0)"
      >
        <template #body="{data}">
          <div v-for="(item) in data.asignBoxes" :key="item.indice">
            <span v-html="findBoxAsign(item)"></span>
          </div>
        </template>
      </Column>
      <Column header="Acciones" style="min-width:8rem" headerStyle="justify-content: center"
              bodyStyle="justify-content: center;text-align: center">
        <template #body="slotProps">
          <Button icon="pi pi-window-maximize" @click="openAsignModalBox(slotProps)" v-if="previewMode || filters.WhsCode"
                  class="p-button-rounded p-button-text p-button-plain" v-tooltip="'Asignar Cajas'"/>
          <Button icon="pi pi-minus-circle" @click="deleteLine(slotProps)" v-if="!previewMode"
                  class="p-button-rounded p-button-text p-button-plain" v-tooltip="'Eliminar'"/>
        </template>
      </Column>
    </DataTable>
  </div>
  <div class="w-full bg-white">
    <div class="w-full grid grid-cols-1 lg:grid-cols-3 gap-2 xl:gap-8 p-4">
      <div class="grid text-xs grid-cols-1 sm:grid-cols-2 lg:grid-cols-2 p-1 gap-2 xl:gap-8 lg:col-span-2">
        <div class="flex flex-col">
          <p class="text-xxs mb-2">
            <strong>Comentarios: </strong>
          </p>
          <Textarea
            v-model="envioTemp.datosLogistica.Comments"
            :disabled="previewMode"
            class="p-inputtext-xxs"
            :autoResize="true"
            rows="2"
            cols="40"
          />
        </div>
        <div>
        </div>
      </div>
      <div class="text-xxs lg:col-span-1">
        <div v-if="!previewMode ? envioTemp.escala : infoGestionEnvio.escala" class="flex justify-between pb-3">
          <span class="text-red-600">Envío con escala en la bodega principal.</span>
        </div>
        <div class="flex justify-between bg-blue-800 text-white rounded-lg p-2 text-sm">
          <span class="font-bold">Total del envio:</span>
          <span class="">{{ $h.formatCurrency(totalEnvio) }}</span>
        </div>
      </div>
    </div>
  </div>
  <div class="bg-white pt-4" v-if="!previewMode">
    <div class="w-full p-4 pt-2 mx-auto lg:w-3/4 xl:w-1/2 flex justify-center items-center">
      <div class="flex w-full justify-center md:justify-between px-2">
        <Button label="Regresar" @click="$router.push({name:'pharmasan.compras.logistica.listar-control-envios-pendientes'})" icon="pi pi-angle-left"/>
        <Button label="Continuar" @click="nextPage()" icon="pi pi-angle-right" iconPos="right"/>
      </div>
    </div>
  </div>
  <openModal ref="openModalBoxes" @deleteAsignMx="deleteAsignMd"/>
  <openAsignModal ref="openAsignModalBoxes" :readOnly="previewMode"/>
  <modalAsignarLotes ref="modalLotes"/>
</template>

<script>
import { computed, ref, reactive, defineAsyncComponent, onMounted } from 'vue'
import _ from 'lodash'
import ControlEnvioService from '../../../services/control.service'
import Swal from 'sweetalert2'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default {
  name: 'infoPrincipal',
  props: {
    previewMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    openModal: defineAsyncComponent(() => import('./modalBoxes.vue')),
    openAsignModal: defineAsyncComponent(() => import('./modalAsignBoxes.vue')),
    modalAsignarLotes: defineAsyncComponent(() => import('./modalAsignarLotes.vue'))
  },
  setup (props, { emit }) {
    const _ControlEnvioService = new ControlEnvioService()
    const router = useRouter()
    const store = useStore()
    const modalLotes = ref()
    const selectedCustomers = ref([])
    const infoSeleccionados = computed(() => store.getters['controlEnvioStorage/getInfoSeleccionados'])

    const infoGestionEnvio = computed(() => {
      const data = store.getters['controlEnvioStorage/getGestionEnvio']
      if (props.previewMode) {
        setComentario(data.datosLogistica.Comments)
      }
      return data
    })
    const envioStorage = computed(() => {
      if (!infoGestionEnvio.value.datosEnvio) return []
      return infoGestionEnvio.value.datosEnvio
    })

    const uniqueWhs = computed(() => {
      if (envio.value.datosEnvio.length) {
        const whsSet = envio.value.datosEnvio.reduce((set, item) => {
          item.distribucion.forEach((dist) => {
            set.add(`${dist.WhsCode}&${dist.WhsName}&${dist.DirBodega}&${dist.responsableBodega}`)
          })
          return set
        }, new Set())
        /* listado de bodegas ordenadas alfabéticamente */
        return [...whsSet].map((whs) => {
          const [WhsCode, WhsName, DirBodega, responsableBodega] = whs.split('&')
          const nombreBodega = `${WhsCode} - ${WhsName}`
          return { WhsCode, DirBodega, WhsName, nombreBodega, responsableBodega }
        }).sort((a, b) => a.WhsCode.localeCompare(b.WhsCode))
      }
      return []
    })
    const totalEnvio = computed(() => {
      let datosEnvio = envioTemp.value.datosEnvio
      if (props.previewMode) {
        datosEnvio = infoGestionEnvio.value.datosEnvio
      }
      return datosEnvio.reduce((acc, val) => acc + val.LineTotal, 0)
    })

    const expandedRows = ref([])
    const bodegas = ref([])
    const openModalBoxes = ref()
    const openAsignModalBoxes = ref()
    const op = ref()
    const info = ref([])
    const tipoDocumento = ref([{ id: 1, name: 'ENTRADAS DE MERCANCÍA' }, { id: 2, name: 'SOLICITUD DE TRASLADO' }, { id: 3, name: 'TRANSFERENCIA' }])
    const filters = reactive({
      typeDoc: null,
      DocNum: null,
      WhsCode: null,
      codBodOrigen: null
    })

    const envio = ref({
      boxes: [],
      datosEnvio: [],
      datosLogistica: {
        Comments: ''
      }
    })
    const envioTemp = ref({
      escala: false,
      boxes: [],
      datosEnvio: [],
      datosLogistica: {
        codBodOrigen: '',
        nomBodOrigen: '',
        dirBodOrigen: '',
        codBodDestino: '',
        nomBodDestino: '',
        dirBodDestino: '',
        responsableBodDestino: '',
        Comments: ''
      }
    })
    const deleteLine = ({ data, index }) => {
      if (data.asignBoxes.length > 0) {
        Swal.fire({
          icon: 'info',
          text: 'Esta linea posee asignación de cajas, por favor desasignelas antes de eliminar.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea eliminar esta linea?',
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then((resp) => {
        if (resp.isConfirmed) {
          envioTemp.value.datosEnvio.splice(index, 1)
          Swal.fire({
            icon: 'success',
            title: 'Éxito',
            text: 'Linea eliminada correctamente',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          })
        }
      })
    }
    const asignarLotes = async (data) => {
      await _ControlEnvioService.getBatchNumbers({ ItemCode: data.ItemCode, codBodOrigen: filters.codBodOrigen }).then((response) => {
        modalLotes.value.openModal(response.data, data)
      })
    }

    const setComentario = (Comment) => {
      envioTemp.value.datosLogistica.Comments = Comment
    }
    /* methods */
    const nextPage = () => {
      if (!envioTemp.value.datosEnvio.length) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Cargue al menos un medicamento'
        })
        return
      }

      if (!envioTemp.value.datosEnvio) return
      // if (envioTemp.value.datosEnvio.some((x) => x.Quantity !== x.asignBoxes.reduce((acc, val) => acc + val.cantidad, 0))) {
      //   Swal.fire({
      //     icon: 'warning',
      //     title: 'Advertencia',
      //     text: 'Existen medicamentos sin cajas asignadas o con cantidades faltantes por asignar, por favor verificar'
      //   })
      //   return
      // }
      if (envioTemp.value.datosEnvio.some((a) => a.lotes.length === 0)) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Existen medicamentos sin lotes asignados por favor asignelos e intentar nuevamente'
        })
        return
      }
      // Si envioTemp.value.boxes es igual a 0, quiere decir que no se ha cargado la información de las cajas
      if (envioTemp.value.boxes.length === 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'No existen cajas para administrar, por favor cargue la información de las cajas antes de continuar.'
        })
        return
      }
      const cUsadas = usedBoxes(envioTemp.value.datosEnvio)
      const cajasVacias = envioTemp.value.boxes.filter(a => !cUsadas.includes(a.indice))
      if (cajasVacias.length > 0) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Existen cajas sin medicamentos asignados, realice la asignación o elimínelas antes de continuar.'
        })
        return
      }
      store.commit('controlEnvioStorage/setGestionEnvio', envioTemp.value)
      router.push({ name: 'pharmasan.compras.logistica.control-envios.informacion-logistica' })
    }
    const toggle = (event, data) => {
      info.value = data.lotes
      op.value.toggle(event)
    }
    const deleteAsignMd = (indice) => {
      envioTemp.value.datosEnvio.forEach((element) => {
        element.asignBoxes = element.asignBoxes.filter((item) => item.indice !== indice)
      })
    }
    const findBoxAsign = ({ indice, tempSalida, cantidad }) => {
      let findBox = envioTemp.value.boxes.find((x) => x.indice === indice)
      if (findBox === undefined) {
        findBox = infoGestionEnvio.value.boxes.find((x) => x.indice === indice)
      }
      return `<strong>Caja: </strong>  ${findBox.nombre} - <strong>Tipo:</strong> ${findBox.tipo === 1 ? 'CADENA FRIO' : 'N/A'}
          <strong>Temperatura:</strong> ${tempSalida ?? ''} - <strong>Cantidad:</strong> ${cantidad}`
    }
    const openModalBox = () => {
      const usadas = usedBoxes(envioTemp.value.datosEnvio)
      openModalBoxes.value.openModalCajas(envioTemp.value.boxes, usadas)
    }
    const usedBoxes = (cUsadas) => {
      return cUsadas.map((x) => {
        return {
          usadas: [...new Set(x.asignBoxes.map((d) => d.indice))]
        }
      }).reduce((acc, val) => {
        return acc.concat(val.usadas)
      }, [])
    }
    const openAsignModalBox = ({ data }) => {
      if (data.lotes.length === 0) {
        Swal.fire({
          icon: 'info',
          text: 'Por favor asigne los lotes primero e inténtelo nuevamente.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3500,
          timerProgressBar: true
        })
        return
      }
      let boxes = envioTemp.value.boxes
      if (props.previewMode) {
        boxes = infoGestionEnvio.value.boxes
      }
      openAsignModalBoxes.value.openAsingModalBoxes(data, boxes, data.asignBoxes)
    }
    const deletedSelection = async () => {
      await Swal.fire({
        icon: 'question',
        title: '¿Esta seguro/a?',
        text: '¿Desea eliminar las lineas seleccionadas?',
        showConfirmButton: true,
        focusCancel: true,
        showCancelButton: true,
        confirmButtonText: 'Si, eliminar',
        cancelButtonText: 'No, cancelar'
      }).then((response) => {
        if (response.isConfirmed) {
          selectedCustomers.value.forEach((element) => {
            envioTemp.value.datosEnvio = envioTemp.value.datosEnvio.filter((x) => x.Key !== element.Key)
            envio.value.datosEnvio = envio.value.datosEnvio.filter((x) => x.Key !== element.Key)
          })
          Swal.fire({
            icon: 'success',
            title: 'Información',
            text: 'Se eliminó la selección de lineas correctamente.',
            showConfirmButton: false,
            timer: 2500,
            timerProgressBar: true
          })
        }
      })
    }
    const onChangeBod = ({ value }) => {
      if (value) {
        const datosOriginalesEnvio = _.cloneDeep(envio.value.datosEnvio)
        envioTemp.value.datosEnvio = datosOriginalesEnvio.flatMap(item => {
          const distribucionFiltrada = item.distribucion.filter(d => d.WhsCode === value)
          if (distribucionFiltrada.length > 0) {
            item.Quantity = distribucionFiltrada.reduce((acc, val) => acc + val.Quantity, 0)
            item.LineTotal = distribucionFiltrada.reduce((acc, val) => acc + val.totalDistribucion, 0)
            return { ...item, distribucion: distribucionFiltrada }
          }
          return []
        })
        if (envioTemp.value.datosEnvio.length) {
          for (const item of envioTemp.value.datosEnvio) {
            const lotesOrdenados = item.lotes.sort((a, b) => {
              const fechaA = new Date(a.ExpDate)
              const fechaB = new Date(b.ExpDate)
              return fechaA - fechaB
            })
            const newListLotes = []
            let sum = 0
            for (const lote of lotesOrdenados) {
              if (sum >= item.Quantity) continue

              if (lote.Quantity >= item.Quantity) {
                lote.Quantity = item.Quantity
              } else if (lote.Quantity >= (item.Quantity - sum)) {
                lote.Quantity = (item.Quantity - sum)
              }
              sum += lote.Quantity
              newListLotes.push(lote)
            }
            item.lotes = newListLotes
          }
        }
        const findInfoBod = uniqueWhs.value.find(a => a.WhsCode === value)
        envioTemp.value.datosLogistica.codBodDestino = value
        envioTemp.value.datosLogistica.nomBodDestino = findInfoBod.WhsName
        envioTemp.value.datosLogistica.dirBodDestino = ['null', ''].includes(findInfoBod.DirBodega) ? null : findInfoBod.DirBodega
        envioTemp.value.datosLogistica.responsableBodDestino = ['null', ''].includes(findInfoBod.responsableBodega) ? null : findInfoBod.responsableBodega
      } else {
        envioTemp.value.datosEnvio = envio.value.datosEnvio
      }
    }
    const onRowSelectAll = (event) => {
      if (event.data.some((x) => x.asignBoxes.length > 0)) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'Las lineas seleccionadas poseen una asignación activa. Se desmarcaran automáticamente.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        }).then((response) => {
          selectedCustomers.value = event.data.filter((a) => a.asignBoxes.length === 0)
        })
      }
    }
    const onRowSelect = (event) => {
      if (event.data.asignBoxes.length > 0) {
        Swal.fire({
          icon: 'info',
          title: 'Información',
          text: 'La linea seleccionada posee una asignación, desasigne las cantidades e inténtelo de nuevo.',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        }).then((response) => {
          selectedCustomers.value = selectedCustomers.value.filter((a) => a.asignBoxes.length === 0)
        })
      }
    }
    const getLoad = async () => {
      if ([undefined, null, ''].includes(filters.DocNum)) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Por favor ingrese un número de documento valido.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      if ([undefined, null, ''].includes(filters.typeDoc)) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'Por favor seleccione un documento e inténtelo nuevamente.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 2500,
          timerProgressBar: true
        })
        return
      }
      const documentoExistente = envio.value.datosEnvio.some(x => {
        let valTipo = 0
        switch (x.typeDoc) {
          case 'EM':
            valTipo = 1
            break
          case 'ST':// verificar para tipo de documento de solicitud de transferencia
            valTipo = 2
            break
        }
        return valTipo === filters.typeDoc && x.DocNum === parseInt(filters.DocNum)
      })
      if (documentoExistente) {
        Swal.fire({
          icon: 'warning',
          title: 'Advertencia',
          text: 'El Número de documento que ingreso ya existe en el listado.',
          toast: true,
          position: 'top-end',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true
        })
        return
      }
      await _ControlEnvioService.getInfoGestion(filters).then(async ({ data }) => {
        await onLoadData(data)
      })
      filters.DocNum = null
      // filters.typeDoc = null
    }
    const onLoadData = (data) => {
      for (const item of data.datosEnvio) {
        envio.value.datosEnvio.push({ ...item, TipoTransferencia: filters.typeDoc })
        envioTemp.value.datosEnvio.push({ ...item, TipoTransferencia: filters.typeDoc })
      }
      if (envioTemp.value.datosLogistica.codBodOrigen === '') {
        envioTemp.value.datosLogistica.codBodOrigen = data.datosLogistica.codBodOrigen
        envioTemp.value.datosLogistica.nomBodOrigen = data.datosLogistica.nomBodOrigen
        envioTemp.value.datosLogistica.dirBodOrigen = data.datosLogistica.dirBodOrigen
        filters.codBodOrigen = data.datosLogistica.codBodOrigen
      }
      if (envioTemp.value.datosEnvio.some((x) => !x.asignBoxes.length) && filters.WhsCode) {
        filters.WhsCode = null
        onChangeBod({ value: null })
      }
    }
    const getBodegas = async () => {
      await _ControlEnvioService.getAllBodegas().then(({ data }) => {
        bodegas.value = data
      })
    }

    onMounted(async () => {
      if (infoSeleccionados.value.DocNums) {
        await _ControlEnvioService.getInfoMasivaGestion(infoSeleccionados.value).then(async ({ data }) => {
          await onLoadData(data)
        })
        filters.DocNum = null
      } else {
        await router.push({ name: 'pharmasan.compras.logistica.listar-control-envios-pendientes' })
      }
      await getBodegas()
    })

    return {
      expandedRows,
      envio,
      uniqueWhs,
      envioTemp,
      filters,
      openModalBoxes,
      openAsignModalBoxes,
      totalEnvio,
      tipoDocumento,
      op,
      info,
      envioStorage,
      nextPage,
      onChangeBod,
      openModalBox,
      openAsignModalBox,
      deleteAsignMd,
      findBoxAsign,
      getLoad,
      toggle,
      getBodegas,
      bodegas,
      modalLotes,
      asignarLotes,
      deleteLine,
      infoGestionEnvio,
      store,
      selectedCustomers,
      onRowSelectAll,
      onRowSelect,
      deletedSelection
    }
  }
}
</script>

<style scoped>
::v-deep(.p-column-header-content) {
  justify-content: center;
}
</style>
